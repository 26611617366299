import React, { useContext } from 'react';
import { Context } from 'store';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Block from '../../components/block/block.js';
import Text from '../../components/text/text.js';
import Link from '../../components/link/link';

import clubImage from '../../assets/images/club.jpg';
import proposalsImage from '../../assets/images/proposals.jpg';

export const TextBlock = ({ title, description, button, link, internal }) => {
  const context = useContext(Context);
  return (
    <div className="case-text">
      <h2>{title}</h2>
      <p>{description}</p>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer" className="case-link">
          {button}
        </a>
      ) : (
        <Link onClick={context.clearState} to={internal} className="case-link">
          {button}
        </Link>
      )}
    </div>
  );
};

export const ImageBlock = ({ classname, img, text, block, line }) => (
  <div
    className={classNames('case-image', `${classname}-group`, {
      'no-blocks': !block
    })}
  >
    <img src={img} alt={classname} />
    <Text text={text} line={line} noBlocks={!block} />

    {block && (
      <Block text={block} />
    )}
  </div>
);

export const Cases = () => {
  const { t } = useTranslation();

  const cases = [
    {
      classname: 'club',
      title: t('goToCampus'),
      description: t('campusDescription'),
      button: t('goToProposals'),
      internal: '/proposals/',
      text: t('campusImageTitle', { returnObjects: true }),
      block: t('campusImageBlock', { returnObjects: true }),
      line: ['rectangle140', 'rectangle190'],
      img: clubImage,
    },
    /* {
      classname: 'simagochi',
      title: t('simagotchi'),
      description: t('simagotchiDescription'),
      button: t('details'),
      link: 'https://www.lifecell.ua/uk/simagochicampus/',
      text: t('youFeed', { returnObjects: true }),
      block: t('iBringCasback', { returnObjects: true }),
      line: ['rectangle150', 'rectangle190'],
      img: simagochiImage,
    }, */
    {
      classname: 'proposals',
      title: t('lifecellOffers'),
      description: t('offersDescription'),
      button: t('goToProposals'),
      internal: '/proposals/',
      text: t('wannaBeALeader', { returnObjects: true }),
      block: t('mangerOfDepartament', { returnObjects: true }),
      line: ['rectangle170', 'rectangle190'],
      img: proposalsImage,
    },
  ];

  return (
    <section className="case" id="proposals">
      {cases.map((item) => (
        <div className={`case-item ${item.classname}`} key={item.classname}>
          <TextBlock {...item} />
          <ImageBlock {...item} />
        </div>
      ))}
    </section>
  );
};
