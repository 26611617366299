import React, { useState, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Context } from 'store';
import { Formik, Form, useField } from 'formik';
import debounce from 'lodash.debounce';
import * as Yup from 'yup';

import env from '@beam-australia/react-env';
import axios from 'lib/axios';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';

import TextField from 'react-lifecell/TextField';
import Select from 'react-lifecell/Select';
import { getValueFromString } from 'react-lifecell/utils';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import Icon from '../../components/icon/icon';
import Loader from '../../components/loader/loader';
import Link from '../../components/link/link';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy');

const re =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/g;

const MaskInput = (props) => {
  const [field, meta] = useField(props);
  const customError = props.error && props.error[props.name] && props.error[props.name][0];

  const handleFocus = (e) => {
    e.target.placeholder = props.name === 'document_student' ? 'AA №12345678' : '12.12.1992';
  }
  const handleBlur = (e) => {
    e.target.placeholder = ''
  }

  return (
    <TextField
      {...field}
      {...props}
      onFocus={handleFocus}
      onBlur={handleBlur}
      classes={`form-field ${props.name}`}
      variant="bottomline"
      maxLength="30"
      error={meta.error || customError}
      inputElement={
        <MaskedInput
          {...props}
          guide
          keepCharPositions
          autoComplete="off"
          placeholderChar={'\u2000'}
          pipe={autoCorrectedDatePipe}
        />
      }
    />
  );
};

const TextInput = (props) => {
  const inputLength = props.name === 'message' ? 2000 : props.name === 'email' ? 254 : 30;
  const [field, meta] = useField(props);
  const customError = props.error && props.error[props.name] && props.error[props.name][0];
  return (
    <TextField
      {...field}
      {...props}
      classes="form-field"
      variant="bottomline"
      error={meta.error || customError}
      maxLength={inputLength}
    />
  );
};

const SelectInput = (props) => {
  const [field, state, { setValue }] = useField(props);
  const customError = props.error && props.error[props.name] && props.error[props.name][0];
  const onChange = (value) => setValue(value);
  return <Select {...field} {...props} onChange={onChange} error={state.error || customError} />;
};

const CheckboxInput = (props) => {
  const { t } = useTranslation();

  const [field, meta] = useField({ ...props, type: 'checkbox' });

  return (
    <div className="form-checkbox">
      <label className="form-checkbox-block">
        <input {...field} {...props} type="checkbox" />
        <span>{t('form.agree1')}</span>
      </label>

      <Link to="/legal/">{t('form.agree2')}</Link>

      {meta.touched && meta.error ? (
        <small className="error">{meta.error}</small>
      ) : props.error && props.error[props.name] ? (
        <small className="error">{props.error[props.name][0]}</small>
      ) : null}
    </div>
  );
};

const FormData = () => {
  const { t } = useTranslation();

  const { state, sendData, generateTransferCode, updateState, formatMsisdn } = useContext(Context);
  const notAuthorizedCustomer = {
    student_card: {},
  };

  const {
    customer,
    contactCategories,
    formType,
    formLoader,
    formError,
    isStudent,
    isCampus,
    formStatus,
    generalError,
    keycloakParams: { msisdn },
  } = state;
  const contactForm = formType === 'contact';
  const profileForm = formType === 'profile';
  const [editable, setEdit] = useState(false);

  if (editable && (formStatus === 'success' || formStatus === 'error')) {
    setEdit(false);
  }

  const {
    last_name,
    first_name,
    middle_name,
    email,
    birth_date,
    gender,
    institution,
    student_card: { series, number } = {},
  } = customer || notAuthorizedCustomer;

  const genderArray = [
    { value: 'M', label: t('form.genderMale') },
    { value: 'F', label: t('form.genderFemale') },
    { value: 'X', label: t('form.genderUndefined') },
    { value: 'NONE', label: t('form.genderUndefined') },
  ];
  const studentDocument = series ? `${series} №${number}` : '';
  const genderValue = genderArray.find((item) => item.value === gender);

  const initialValues = {
    last_name: last_name || '',
    first_name: first_name || '',
    middle_name: middle_name || '',
    email: email || '',
    msisdn: formatMsisdn(msisdn) || '',
    institution: institution && institution.value ? institution : '',
    document_student: studentDocument,
    birth_date: birth_date || '',
    gender: genderValue || '',
    agree: false,
  };

  const validationData = Yup.object().shape({
    last_name:
      !contactForm &&
      Yup.string()
        .max(30, t('form.error.maxLength'))
        .matches(/^[a-zа-яіїє '’‘`-]+$/i, t('form.error.fieldSymbols'))
        .required(t('form.error.fieldRequired')),
    first_name: Yup.string()
      .max(30, t('form.error.maxLength'))
      .matches(/^[a-zа-яіїє '’‘`-]+$/i, t('form.error.fieldSymbols'))
      .required(t('form.error.fieldRequired')),
    middle_name: Yup.string()
      .max(30, t('form.error.maxLength'))
      .matches(/^[a-zа-яіїє '’‘`-]+$/i, t('form.error.fieldSymbols')),
    email: formType !== 'sign' && Yup.string()
      .max(254, t('form.error.maxEmailLength'))
      .email(t('form.error.emailField'))
      .test('is-email', t('form.error.emailField'), (value) => !value || value.match(re))
      .test('is-ru', t('form.error.emailFieldRu'), (value) => !value || value.slice(-3) !== '.ru'),
    msisdn:
      profileForm &&
      Yup.string()
        .required(t('form.error.fieldRequired'))
        .test(
          'is-msisdn',
          t('form.error.msisdnValid'),
          (value) => value && value.replace(/\s/g, '').length === 10,
        ),

    institution: profileForm && Yup.object(),
    document_student:
      !contactForm &&
      Yup.string()
        .required(t('form.error.fieldRequired'))
        .test(
          'is-student-series',
          t('form.error.latinSymbols'),
          (value) => value && value.split(/[a-zA-Z]/).length < 2,
        )
        .test(
          'is-student-number',
          t('form.error.wrongStudentDocument'),
          (value) =>
            value && value.split(/[а-яА-Яa-zA-Z]/).length === 3 && value.split(/\d/).length === 9,
        ),
    birth_date:
      !contactForm &&
      Yup.string()
        .required(t('form.error.fieldRequired'))
        .test(
          'is-date',
          t('form.error.dateDocument'),
          (value) =>
            value &&
            +value.split('.')[0] <= 31 &&
            +value.split('.')[1] <= 12 &&
            +value.split('.')[2] > 1929 &&
            +value.split('.')[2] < 2010,
        ),
    gender: !contactForm && Yup.object().required(t('form.error.fieldRequired')),
    category:
      contactForm &&
      !!contactCategories.length &&
      Yup.object().required(t('form.error.fieldRequired')),
    message:
      contactForm &&
      Yup.string()
        .max(2000, t('form.error.maxMessageLength'))
        .required(t('form.error.fieldRequired')),
    agree:
      !profileForm &&
      Yup.boolean()
        .required(t('form.error.fieldRequired'))
        .oneOf([true], t('form.error.fieldRequired')),
  });

  const onSubmit = (values) => {
    const data = Object.assign({}, values);
    const { first_name, email, message, category, agree } = values;
    const comment = { first_name, email, message, category, agree };

    setValue('');
    updateState('formError', null);

    if (!contactForm) {
      data.gender = values.gender.value;
      data.institution = values.institution.value;
      if (values.document_student) {
        data.document_series = values.document_student.substring(0, 2).toUpperCase();
        data.document_number = getValueFromString(values.document_student, 'digits');
        delete data.document_student;
      }
      if (profileForm) {
        data.msisdn = getValueFromString(values.msisdn, 'digits');
        delete data.agree;
      }

      if (formatMsisdn(msisdn) === values.msisdn) {
        if (formType === 'sign') {
          delete data.email;
        }
        sendData(data);
      } else {
        generateTransferCode(data);
      }
    } else {
      comment.category = category ? category.value : null;
      sendData(comment);
    }
  };

  const disableField = (value) => {
    if (profileForm) {
      return !editable;
    } else {
      return !!value;
    }
  };

  const handleEdit = () => {
    updateState('formStatus', 'form');
    setEdit(true);
  };

  const handleCancelEdit = (resetForm) => {
    setEdit(false);
    resetForm();
  };

  const [inputValue, setValue] = useState('');

  const handleInputChange = (value) => setValue(value);

  const loadOptions = React.useCallback(
    debounce((inputValue, callback) => {
      axios
        .get(`${env('UNIVERSITY')}?q=${inputValue}`)
        .then(({ data }) => {
          return data.map((item) => {
            return { value: item.id, label: item.name };
          });
        })
        .then((options) => callback(options));
    }, 1000),
    [],
  );

  const formClasses = classNames('form-container', {
    edit: profileForm && editable,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationData}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ resetForm }) => (
        <Form noValidate>
          <div className={formClasses}>
            {!contactForm && (
              <TextInput
                label={t('form.lastName')}
                disabled={disableField(initialValues.last_name)}
                name="last_name"
                type="text"
                error={formError}
              />
            )}

            <TextInput
              label={t('form.firstName')}
              disabled={disableField(initialValues.first_name)}
              name="first_name"
              type="text"
              error={formError}
            />

            {!contactForm && (
              <TextInput
                label={t('form.middleName')}
                disabled={disableField(initialValues.middle_name)}
                name="middle_name"
                type="text"
                error={formError}
              />
            )}

            {formType !== 'sign' && (
              <TextInput
                label={t('form.email')}
                disabled={disableField(initialValues.email)}
                name="email"
                type="email"
                error={formError}
              />
            )}

            {profileForm && (
              <>
                <MaskInput
                  label={t('form.msisdnField')}
                  disabled={disableField(initialValues.msisdn)}
                  name="msisdn"
                  type="text"
                  mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]}
                  error={formError}
                />
                <SelectInput
                  label={t('form.universityField')}
                  disabled={disableField(initialValues.institution)}
                  name="institution"
                  classes="form-select"
                  error={formError}
                  async
                  cacheOptions
                  menuIsOpen={inputValue}
                  getOptionValue={(e) => e.value}
                  getOptionLabel={(e) => e.label}
                  loadOptions={loadOptions}
                  onInputChange={handleInputChange}
                  loadingMessage={() => ''}
                  noOptionsMessage={() =>
                    inputValue ? t('form.error.notFound') : t('form.startSearch')
                  }
                />
              </>
            )}

            {!contactForm && (
              <>
                <MaskInput
                  label={t('form.student')}
                  disabled={disableField(initialValues.document_student)}
                  name="document_student"
                  type="text"
                  mask={[
                    /[а-яА-Яa-zA-Z]/,
                    /[а-яА-Яa-zA-Z]/,
                    ' ',
                    '№',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  error={formError}
                />

                {profileForm && !isStudent && isCampus && (
                  <small className="message-error">{t('form.error.expiredDocument')}</small>
                )}

                <MaskInput
                  label={t('form.date')}
                  disabled={disableField(initialValues.birth_date)}
                  name="birth_date"
                  type="text"
                  mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                  error={formError}
                />

                <SelectInput
                  label={t('form.gender')}
                  disabled={disableField(initialValues.gender)}
                  name="gender"
                  options={genderArray}
                  isSearchable={false}
                  classes="form-select gender"
                  error={formError}
                />
              </>
            )}

            {contactForm && !!contactCategories.length && (
              <SelectInput
                label={t('form.categoryField')}
                name="category"
                options={contactCategories}
                isSearchable={false}
                classes="form-select"
                error={formError}
              />
            )}

            {contactForm && (
              <TextInput
                label={t('form.messageField')}
                name="message"
                type="text"
                error={formError}
              />
            )}

            {!profileForm && (
              <CheckboxInput name="agree" id="agree" type="checkbox" error={formError} />
            )}

            {profileForm && (
              <div className="form-profile-status">
                {!editable ? (
                  <div className="form-submit" onClick={() => handleEdit()}>
                    <Icon icon="edit" iconClass="button-icon" />
                    <span>{t('editData')}</span>
                  </div>
                ) : (
                  <div className="form-submit" onClick={() => handleCancelEdit(resetForm)}>
                    <Icon icon="edit_cancel" iconClass="button-icon" />
                    <span>{t('cancel')}</span>
                  </div>
                )}

                {formStatus === 'success' ? (
                  <p className="success">{t('successChange')}</p>
                ) : formStatus === 'error' && generalError ? (
                  <p className="error">
                    {generalError === t('generalError') ? (
                      <Trans
                        i18nKey="generalError"
                        components={[<span className="title" key="span" />, <br key="br" />]}
                      />
                    ) : (
                      generalError
                    )}
                  </p>
                ) : null}
              </div>
            )}

            {formLoader && <Loader />}

            {(!profileForm || editable) && (
              <button className="form-submit" type="submit">
                {t('confirm')}
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormData;
