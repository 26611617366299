import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import FormData from '../../components/formData/formData.js';
import FormSuccess from '../../components/formSuccess/formSuccess.js';
import GoBack from '../../components/goBack/goBack.js';
import Link from '../../components/link/link';
import AuthButton, { AuthMessage } from '../../components/authButton/authButton.js';
import { Context } from 'store';

const FormContainer = () => {
  const { t } = useTranslation();

  const context = useContext(Context),
    { formType, keycloakParams, isCampus } = context.state,
    { auth } = keycloakParams;

  const btnClick = () => {
    context.clearState();
    window.scrollTo({ top: 0 });
  };

  const pageStatus = () => {
    if (formType === 'contact') return 'contact';

    if (!auth) return 'login';

    if (isCampus) return 'сampus';
    return 'form';
  };

  const leftText = () => {
    if (pageStatus() === 'contact') {
      return (
        <Trans
          i18nKey="contactUsMessage"
          components={[<span className="title" key="span" />, <br key="br" />]}
        />
      );
    }

    if (pageStatus() === 'login') {
      return <span className="title">{t('authToContinue')}</span>;
    }

    if (pageStatus() === 'сampus') {
      return <span className="title">{t('form.alreadyInCampus')}</span>;
    }

    if (pageStatus() === 'form') {
      return (
        <span>
          {t('form.completeRegistration1')}
          <br />
          {t('form.completeRegistration2')}
        </span>
      );
    }
  };

  const rightBlock = () => {
    if (pageStatus() === 'contact' || pageStatus() === 'form')
      return <FormData context={context} />;
    if (pageStatus() === 'login') return <AuthButton />;
    if (pageStatus() === 'сampus')
      return (
        <>
          <AuthMessage />
          <Link className="button-yellow" to="/proposals/">
            {t('proposals')}
          </Link>
          <Link className="button-yellow" to="/profile/" onClick={() => btnClick()}>
            {t('personalData')}
          </Link>
        </>
      );
  };

  return (
    <>
      <div className="form-part">
        {!isCampus && <AuthMessage />}
        <h3>{leftText()}</h3>
      </div>
      {formType && <div className="form-part">{rightBlock()}</div>}
    </>
  );
};

const TextContainer = () => {
  const { t } = useTranslation();

  const context = useContext(Context);
  const { formType, formStatus, customer, generalError, maintenance, disableReg, redirectAfterRegistration } = context.state;

  const textFormAlready = formStatus === 'already';
  const textByfAlready = formStatus === 'byf_already';
  const textFormError = formStatus === 'error';
  const textDataContact = formStatus === 'message' && formType === 'contact';
  const textSuccessOmo = formStatus === 'success' && customer && customer.is_omo;
  const textSuccessNotOmo = formStatus === 'success' && customer && !customer.is_omo;

  const getText = () => {
    if (generalError) {
      return (
        <Trans
          i18nKey="generalError"
          components={[<span className="title" key="span" />, <br key="br" />]}
        />
      )
    }

    if (formType !== 'contact' && disableReg?.status === 'invalid_tariff') {
      return disableReg.text;
    }

    if (textByfAlready) {
      return <Trans i18nKey="byfAlready" components={[<br key="br" />]} />;
    }

    if (textFormAlready) {
      return <span className="title">{t('form.alreadyInCampus')}</span>;
    }

    if (textDataContact) {
      return (
        <Trans
          i18nKey="form.comment"
          components={[<span className="title" key="span" />, <br key="br" />]}
        />
      );
    }

    if (redirectAfterRegistration && (textSuccessOmo || textSuccessNotOmo)) {
      window.location.href = redirectAfterRegistration;
    }

    if (textSuccessOmo) {
      return (
        <>
          <span className="title">{t('welcomeToCampus')}</span>
          <br />
          <Trans
            i18nKey="form.textSuccessOmo"
            components={[
              <br key="br" />,
              <br key="br1" />,
              <a href="https://mnp.lifecell.ua/uk/" target="_blank" key="a" />,
              <br key="br2" />,
              <br key="br3" />,
              <a
                class="success-links"
                href="https://shop.lifecell.ua/uk/"
                target="_blank"
                key="a1"
              />,
            ]}
          />
        </>
      );
    }

    if (textSuccessNotOmo) {
      return (
        <>
          <span className="title">{t('welcomeToCampus')}</span>
          <br />
          <Trans i18nKey="form.textSuccess" components={[<br key="br" />]} />
        </>
      );
    }

    if (textFormError) {
      return (
        <Trans
          i18nKey="generalError"
          components={[<span className="title" key="span" />, <br key="br" />]}
        />
      );
    }

    if (maintenance) {
      return (
        <Trans
          i18nKey="form.textMaintenance"
          components={[<span className="title" key="span" />, <br key="br" />]}
        />
      );
    }

    return (
      <Trans
        i18nKey="generalError"
        components={[<span className="title" key="span" />, <br key="br" />]}
      />
    );
  };

  const already = formStatus === 'already' || formStatus === 'byf_already';

  return (
    <div className="form-text">
      <h3>{getText()}</h3>

      {formStatus === 'success' ? (
        <FormSuccess />
      ) : already ? (
        <Link className="button-yellow" to="/proposals/">
          {t('proposals')}
        </Link>
      ) : (
        <Link className="button-yellow" to="/">
          {t('home')}
        </Link>
      )}
    </div>
  );
};

const FormBlock = ({ form }) => {
  const context = useContext(Context);
  const { i18n } = useTranslation();

  const { state, updateState, clickLoginBth } = context;
  const { maintenance, formStatus, formType, generalError, disableReg, keycloakParams } = state;
  const successPage = ['already', 'byf_already', 'success', 'error', 'message'];
  const showTextContainer =
    ((disableReg || maintenance) && form !== 'contact') ||
    generalError ||
    successPage.includes(formStatus);

  if (form !== formType) updateState('formType', form);

  if (!keycloakParams.auth) {
    const url = keycloakParams.createLoginUrl({ locale: i18n.language || 'uk' });

    window.location.replace(url);
    
    return null;
  }

  window.scrollTo({ top: 0 });

  return (
    <section className="form">
      <div className="section-in">
        <GoBack type="white" />
        <div className="form-in">{showTextContainer ? <TextContainer /> : <FormContainer />}</div>
      </div>
    </section>
  );
};

export default FormBlock;
