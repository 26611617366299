import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from 'store';
import { trackGAEvent } from '../../lib/analytics';
import Icon from '../../components/icon/icon.js';
import Link from '../../components/link/link';
import { ImageBlock } from '../../containers/cases/cases.js';

import mainImage from '../../assets/images/want_to_lifecell.jpg';

const Main = () => {
  const { t } = useTranslation();

  const context = useContext(Context);
  const { isCampus } = context.state;

  const btnClick = (type) => {
    context.clearState();
    window.scrollTo({ top: 0 });
    trackGAEvent({ category: 'button', action: 'click', label: `link to ${type} form` });
  };

  const arrowScroll = () => {
    const headerElement = document.querySelector('.header'),
      communityElement = document.getElementById('proposals');

    window.scrollTo({
      top: communityElement.offsetTop - headerElement.clientHeight + 10,
      behavior: 'smooth',
    });
  };

  return (
    <section className="main" id="lifecamp">
      <ImageBlock
        classname="mainpage"
        img={mainImage}
        text={t('youCanMore', { returnObjects: true })}
        line={['rectangle140', 'rectangle150']}
      />

      {isCampus ? (
        <Link to="/profile/">
          <p className="button-yellow" onClick={() => btnClick('profile')}>
            {t('personalData')}
          </p>
        </Link>
      ) : (
        <Link to="/sign-up/">
          <p className="button-yellow" onClick={() => btnClick('registration')}>
            {t('campusRegistration')}
          </p>
        </Link>
      )}

      <div className="arrow_scroll" onClick={() => arrowScroll()}>
        <Icon icon="arrow-scroll" width="40" height="45" />
      </div>
    </section>
  );
};

export default Main;
