import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import initSSO from '../lib/sso';
import Cookies from 'js-cookie';
import env from '@beam-australia/react-env';
import UAParser from 'ua-parser-js';
import { trackGAEvent } from '../lib/analytics';
import axios from 'lib/axios';

export const Context = React.createContext();
const history = createBrowserHistory();
const formStatusArray = ['success', 'message', 'error'];

class Provider extends Component {
  state = {
    ready: false,
    error: false,
    menuStatus: false,
    formType: null,
    formError: null,
    formLoader: null,
    formStatus: 'form',
    formPosition: null,
    disableReg: null,
    serviceOrderStatus: null,
    referralsNumber: null,
    inviteCode: null,
    acceptCode: null,
    loader: false,
    discountButtons: null,
    prevLocation: null,
    currentLocation: window.location.href,
    maintenance: false,
    hideChatBot: false,
    isCampus: false,
    isStudent: false,
    redirectAfterRegistration: false,
  };

  UAParser = new UAParser(window.navigator.userAgent);
  somethingWentWrong = `${this.props.t('form.error.somethingWentWrong')} ${this.props.t(
    'form.error.tryAgain',
  )}`;
  device = {
    isMobile: this.UAParser.getDevice().type === 'mobile',
  };

  render() {
    return (
      <Context.Provider
        value={{
          state: this.state,
          device: this.device,
          goBack: this.goBack,
          getOffer: this.getOffer,
          closeMenu: this.closeMenu,
          keycloakLogout: this.keycloakLogout,
          logout: this.logout,
          changeFormStatus: this.changeFormStatus,
          updateCustomer: this.updateCustomer,
          orderService: this.orderService,
          updateState: this.updateState,
          buildProposals: this.buildProposals,
          generateInviteCode: this.generateInviteCode,
          generateTransferCode: this.generateTransferCode,
          cancelDiscount: this.cancelDiscount,
          changeFormPosition: this.changeFormPosition,
          clickLoginBth: this.clickLoginBth,
          formatMsisdn: this.formatMsisdn,
          clearState: this.clearState,
          sendData: this.sendData,
          getuser: this.getUser,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }

  checkThirdCookies = () => {
    return new Promise((resolve) => {
      let frame = document.createElement('iframe');
      frame.style.display = 'none';
      frame.src = window.location.origin + '/check-sso.html';

      const cookiesMessage = (event) => {
        if (event.data === frame.src) {
          const thirdCookies = event.data === 'supported';
          resolve({ thirdCookies });
          document.body.removeChild(frame);
          window.removeEventListener('message', cookiesMessage);
        }
      };

      window.addEventListener('message', cookiesMessage, false);
      document.body.appendChild(frame);
    });
  };

  generateToken = () => {
    return new Promise((resolve, reject) => {
      this.state.keycloakParams
        .updateToken(10)
        .then(() => resolve())
        .catch((e) => {
          reject();
          this.setState({ error: true });
        });
    });
  };

  changeFormPosition = () => {
    const { formPosition } = this.state,
      pathname = window.location.pathname,
      anchor = document.querySelector('.byf'),
      byfStatus = pathname.includes('byf'),
      anchorSimagochi = document.querySelector('.simagochi'),
      simagochiStatus = pathname.includes('simagochi'),
      updateStatus =
        byfStatus || pathname === '/'
          ? 'byf'
          : pathname.includes('proposals')
            ? 'proposals'
            : pathname.includes('sign-up')
              ? 'sign'
              : pathname.includes('contact-us')
                ? 'contact'
                : pathname.includes('accept')
                  ? 'accept'
                  : 'other';

    if (formPosition !== updateStatus) this.setState({ formPosition: updateStatus });

    if (byfStatus && anchor) {
      window.scrollTo({
        top: anchor.offsetTop - 100,
        behavior: 'smooth',
      });
    }

    if (simagochiStatus && anchorSimagochi) {
      window.scrollTo({
        top: anchorSimagochi.offsetTop - 100,
        behavior: 'smooth',
      });
    }
  };

  changeFormStatus = (formStatus, text) => {
    this.setState(
      {
        formStatus: formStatus,
        generalError: text,
      },
      () => {
        const { activeOffer, formType } = this.state;
        if (formType !== 'profile' && !activeOffer) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        if (activeOffer) {
          if (activeOffer['service'] && activeOffer['action'] !== 'disable') {
            this.checkDiscount();
          } else {
            this.orderService(activeOffer['service'], activeOffer['id'], activeOffer['action']);
          }
        }
      },
    );
  };

  updateCustomer = (customer) => this.setState({ customer: customer });

  updateState = (elem, value) => this.setState({ [elem]: value });

  formatMsisdn = (msisdn) => {
    if (/^\d{12}$/.test(msisdn)) {
      return `${msisdn.slice(2, 5)} ${msisdn.slice(5, 8)} ${msisdn.slice(8, 10)} ${msisdn.slice(
        10,
        12,
      )}`;
    } else if (/^\d{10}$/.test(msisdn)) {
      return `${msisdn.slice(0, 3)} ${msisdn.slice(3, 6)} ${msisdn.slice(6, 8)} ${msisdn.slice(
        8,
        10,
      )}`;
    } else {
      return null;
    }
  };

  cancelDiscount = () => {
    this.setState({
      discountButtons: null,
      activeOffer: null,
    });
  };

  checkDiscount = async () => {
    const { activeOffer } = this.state;
    this.setState({ loader: true });
    await this.generateToken();

    axios({
      method: 'get',
      url: `${env('SERVICES_INFO')}?service=${activeOffer['id']}`,
    })
      .then(({ data }) => {
        if (data.result === 'success') {
          if (data.buttons === 'none') {
            this.orderService(activeOffer['service'], activeOffer['id'], activeOffer['action']);
          } else if (!data.buttons) {
            this.setState({
              serviceOrderStatus: this.somethingWentWrong,
              loader: false,
            });
          } else {
            this.setState({
              discountText: data.text,
              discountButtons: data.buttons,
            });
          }
        } else {
          const error = data.errors[0];
          this.setState({
            serviceOrderStatus: error[Object.keys(error)[0]][0],
            loader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          serviceOrderStatus: this.somethingWentWrong,
          loader: false,
        });
      });
  };

  generateTransferCode = async (formData) => {
    this.setState({ loader: true });
    await this.generateToken();

    axios({
      method: 'get',
      url: env('TRANSFER'),
    })
      .then(({ data }) => {
        if (data.result === 'success') {
          formData.kc_state = data.state;
          localStorage.setItem('transferData', JSON.stringify(formData));
          this.logout();
        } else {
          this.setState({ formLoader: false });
          this.changeFormStatus('error', this.props.t('generalError'));
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        this.changeFormStatus('error', this.props.t('generalError'));
      });
  };

  orderService = async (service, id, action) => {
    this.setState({ loader: true });
    await this.generateToken();
    const token = localStorage.getItem('kc_token');

    trackGAEvent({
      category: 'button',
      action: 'click',
      label: `start order ${id} proposal`,
    });
    window.dataLayer.push({
      'event': 'сta_click',
      'cta_type': 'get',
      'service_category': 'loyalty',
      'service_name': 'campus',
      'option_details': id,
    });

    const serviceType = service ? 'services' : 'campaigns';
    const serviceAction = action === 'disable' ? 'refuse' : 'order';
    this.setState({ loader: true });
    axios({
      method: 'post',
      url: `${env('API')}${serviceType}/${id}/${serviceAction}/`,
      headers: { 'Authorization': `Token ${token}` },
    })
      .then(({ data }) => {
        if (data.result === 'success') {
          trackGAEvent({
            category: 'button',
            action: 'click',
            label: `success order ${id} proposal`,
          });
          window.dataLayer.push({
            'event': 'order',
            'flow_step': '2_success_order',
            'service_category': 'loyalty',
            'service_name': 'campus',
            'option_details': id,
          });

          const success = data.texts[0];
          this.setState({
            serviceOrderStatus: success[Object.keys(success)[0]],
            loader: false,
          });
        } else {
          const error = data.errors[0];
          this.setState({
            serviceOrderStatus: error[Object.keys(error)[0]][0],
            loader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          serviceOrderStatus: this.somethingWentWrong,
          loader: false,
        });
      });
  };

  getOffer = (service, id, action) => {
    trackGAEvent({
      category: 'button',
      action: 'click',
      label: `get ${id} proposal`,
    });

    const data = { service, id, action };
    this.setState({
      formStatus: 'form',
      serviceOrderStatus: null,
      activeOffer: data,
    });
    this.changeFormStatus('form');
  };

  generateInviteCode = async () => {
    await this.generateToken();
    axios({
      method: 'post',
      url: env('INVITE'),
    })
      .then(({ data }) => {
        if (data.result === 'success' && data.short_url) {
          this.setState({
            inviteCode: data.short_url,
            referralsNumber: data.referrals_left,
          });
        } else {
          const error = data.errors[0];
          this.setState({
            serviceOrderStatus: error[Object.keys(error)[0]][0],
          });
        }
      })
      .catch((error) => {
        this.setState({
          serviceOrderStatus: this.somethingWentWrong,
        });
      });
  };

  validateAcceptCode = () => {
    axios({
      method: 'get',
      url: `${env('VALIDATE')}${this.state.acceptCode}/`,
    })
      .then(({ data }) => {
        if (data.result !== 'success') {
          const error = data.errors[0];
          this.setState({
            generalError: error[Object.keys(error)[0]][0],
          });
        }
        this.setState({ ready: true }, () => this.changeFormPosition());
      })
      .catch((error) => {
        this.setState({
          error: error?.response?.status !== 429 || 'manyRequests',
        });
      });
  };

  sendData = async (requestData) => {
    const { acceptCode, formPosition, formType } = this.state;

    trackGAEvent({
      category: 'button',
      action: 'click',
      label: `send data from ${formPosition} form`,
    });

    this.setState({ formLoader: true });

    const requestUrl = () => {
      if (formType === 'profile') return env('USER');
      if (formType === 'sign') return env('USER');
      if (formType === 'contact') return env('COMMENT_SEND');
      if (formType === 'accept') return `${env('ACCEPT')}${acceptCode}/`;
    };

    const formStatus = formType === 'contact' ? 'message' : 'success';
    const requestMethod = formType === 'profile' ? 'put' : 'post';

    if (formType !== 'contact') {
      await this.generateToken();
    }

    const { kc_state } = requestData;

    if (requestData.kc_state) delete requestData.kc_state;

    axios({
      method: requestMethod,
      url: requestUrl(),
      data: requestData,
      headers: {
        ...(kc_state && { 'x-state': kc_state }),
      },
    })
      .then(({ data }) => {
        this.updateState('maintenance', data.maintenance);
        if (data.result === 'success') {
          if (formType === 'sign' || formType === 'accept' || formType === 'profile') {
            this.getUser('update');
          }

          this.setState({ formLoader: false, transferData: null }, () => {
            this.changeFormStatus(formStatus);
            trackGAEvent({
              category: 'request',
              action: 'event',
              label: `success answer from ${formPosition} form`,
            });
          });
        } else {
          this.setState({
            formLoader: false,
            formError: data.errors[0],
          });
          if (data.error_code === 'B-APP-10051') {
            trackGAEvent({
              category: 'request',
              action: 'event',
              label: `sent data in the queue`,
            });
          }
          if (data.errors[0]['__all__'][0]) {
            this.changeFormStatus('error', data.errors[0]['__all__'][0]);
          }
        }
      })
      .catch((error) => {
        const errorMany =
          !!error.response && error.response.status === 429 && formType === 'profile';
        const errorStatus = !!error.response && error.response.status !== 200;
        const errorManyText = `${this.props.t('form.error.manyRequests1')} ${this.props.t(
          'form.error.manyRequests2',
        )}`;

        this.setState({ formLoader: false });

        if (errorStatus || error.request) {
          this.changeFormStatus('error', errorMany ? errorManyText : this.props.t('generalError'));
        }
      });
  };

  getUser = async (type) => {
    await this.generateToken();

    axios({
      method: 'get',
      url: env('USER'),
    })
      .then(({ data: { customer, errors } }) => {
        const invalidTariff = errors.status === 'invalid_tariff';
        const generalError = errors.status === 'general_error';

        if (invalidTariff) this.setState({ disableReg: errors });

        if (customer) {
          this.updateCustomer(customer);
          this.defineUser(customer);

          if (generalError) {
            this.setState({ error: true });
          } else {
            if (type === 'init') this.getProposals();
          }
        } else {
          this.setState({ error: true });
        }
      })
      .catch((error) => {
        console.log(error);

        this.setState({
          error: error?.response?.status !== 429 || 'manyRequests',
        });
      })
      .finally(() => {
        this.setState({ loader: false });
      });
  };

  getProposals = (e) => {
    axios({
      method: 'get',
      url: env('DATA'),
    })
      .then(({ data }) => {
        const path = window.location.pathname;

        const categories = data.customer_request_categories.map(({ id, name }) => {
          return { value: id, label: name };
        });

        this.setState({
          maintenance: data.maintenance,
          contactCategories: categories,
          campaigns: data.offer,
          legal: data.legal,
          faq: data.faq,
          hideChatBot: data.hide_chat_bot,
        });
        this.buildProposals();

        if (path.includes('accept/')) {
          const code = path.split('/').filter(Boolean).slice(-1)[0];
          this.setState({ acceptCode: code }, () => this.validateAcceptCode());
        } else {
          this.setState({ ready: true }, () => {
            setTimeout(() => this.changeFormPosition(), 1000);
          });
        }
      })
      .catch((error) => {
        console.log(error);

        this.setState({
          error: error?.response?.status !== 429 || 'manyRequests',
        });
      })
      .finally(() => {
        this.setState({ loader: false });
      });
  };

  defineUser = (customer) => {
    const { student_state: status } = customer;
    if (status === 'ACT_STUDENT') {
      this.setState({ isCampus: true, isStudent: true });
    } else if (status === 'DEACT_STUDENT') {
      this.setState({ isCampus: true, isStudent: false });
    } else {
      this.setState({ isCampus: false, isStudent: false });
    }
  };

  checkNotfound = (name) => {
    const { serviceOrderStatus, formStatus, prevLocation, currentLocation } = this.state;
    const data = JSON.stringify({ serviceOrderStatus, formStatus });
    axios({
      method: 'post',
      url: env('CHECK_NOTFOUND'),
      data: {
        name: name,
        current_url: currentLocation,
        previous_url: prevLocation,
        object: data,
      },
    });
  };

  closeMenu = () => {
    const { menuStatus } = this.state;
    this.setState(() => ({ menuStatus: !menuStatus }));
  };

  buildProposals = () => {
    const proposalsList = [];
    const proposalEvents = [];
    const itProposalsList = [];

    const { campaigns, menuStatus } = this.state;

    campaigns.map((item, index, self) => {
      const itItem = item.location === 2 || item.location === 0;

      if (item.location !== 2 && item.location !== 666) proposalsList.push(item);
      if (item.event && self.indexOf(item) === index) proposalEvents.push(item);
      if (itItem) itProposalsList.push(item);

      return null;
    });

    this.setState({
      proposalsList: proposalsList,
      itProposalsList: itProposalsList,
      proposalEvents: proposalEvents.slice(0, 1),
    });

    if (menuStatus) this.setState({ menuStatus: !menuStatus });
  };

  clearState = () => {
    this.setState({
      formError: null,
      formType: null,
      formLoader: null,
      formStatus: 'form',
      serviceOrderStatus: null,
      activeOffer: null,
      generalError: null,
      discountButtons: null,
      transferData: null,
    });
  };

  goBack = () => {
    const { formStatus } = this.state;
    !formStatusArray.includes(formStatus) ? history.goBack() : history.go(-2);
  };

  clickLoginBth = () => {
    const { keycloakParams, transferData } = this.state;
    if (transferData) {
      localStorage.setItem('transferData', JSON.stringify(transferData));
    }
    keycloakParams.login({ locale: this.props.language || 'uk' });
  };

  keycloakLogout = () => {
    const { keycloakParams } = this.state;
    keycloakParams.logout({
      redirectUri: keycloakParams.createLoginUrl({ locale: this.props.language || 'uk' }),
      locale: this.props.language || 'uk',
    });
  };

  logout = () => {
    this.state.keycloakParams.logout();
    localStorage.removeItem('kc_token');
    localStorage.removeItem('kc_refreshToken');
  }

  keycloakUpdate = (keycloak) => {
    const keycloakParams = {
      auth: keycloak.authenticated,
      createLoginUrl: keycloak.createLoginUrl,
      login: keycloak.login,
      logout: keycloak.logout,
      updateToken: keycloak.updateToken,
      isTokenExpired: keycloak.isTokenExpired,
      createLoginUrl: keycloak.createLoginUrl,
      msisdn: keycloak.tokenParsed && keycloak.tokenParsed.msisdn,
    };

    this.setState({ keycloakParams });
  };

  keycloakCallback = (auth) => (auth ? this.getUser('init') : this.getProposals());

  async componentDidMount() {
    const transferData = localStorage.getItem('transferData');

    if (transferData) {
      this.setState({ transferData: JSON.parse(transferData) });
      localStorage.removeItem('transferData');
    }

    const { thirdCookies } = await this.checkThirdCookies();
    if (window.keycloak) {
      initSSO(this.keycloakUpdate, this.keycloakCallback, thirdCookies);
    } else {
      this.setState({ error: true });
    }
    window.addEventListener('popstate', () => this.clearState());
  }

  componentDidUpdate(prevProps) {
    const { currentLocation } = this.state;
    const changeLocation = window.location.href;

    if (this.props.language !== prevProps.language) {
      this.keycloakCallback(window.keycloak.authenticated);
      this.setState({ loader: true });
    }

    if (currentLocation !== changeLocation) {
      this.setState(
        {
          prevLocation: currentLocation,
          currentLocation: window.location.href,
        },
        () => {
          this.changeFormPosition();
          if (changeLocation.includes('/not-found/')) this.checkNotfound('not-found');
          if (changeLocation.includes('/proposals/=')) this.checkNotfound('proposals');
        },
      );
    }
  }
}

export default Provider;
