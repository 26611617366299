import React, { useContext, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import Icon from '../../components/icon/icon';
import AuthHeader from '../../components/authHeader/authHeader';
import Link from '../../components/link/link';
import { Context } from 'store';
import LanguageSelector from 'components/languageSelector/languageSelector';
import telegram from '../../assets/images/telegram.png';


const TitleMenu = (props) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const context = useContext(Context);
  const { menuStatus, isCampus } = context.state;

  const handleMenuClick = () => {
    context.clearState();
    context.changeFormPosition();
    menuStatus && props.closeMenu();
  };

  const mainMenuItems = [
    {
      title: t('registration'),
      link: '/sign-up/',
    },
    {
      title: t('proposals'),
      link: '/proposals/',
    },
    {
      title: t('university'),
      link: '/university/',
    },
  ];

  return (
    <div className="header-menu-block">
      {mainMenuItems.map(({ title, link }, index) => {
        if (isCampus && !index) return null;

        return (
          <HashLink key={index} to={`/${language}${link}`} onClick={() => handleMenuClick()}>
            {title}
          </HashLink>
        );
      })}
      {/* <a
        href="https://www.lifecell.ua/uk/simagochicampus/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => handleMenuClick()}
      >
        {t('simagotchi')}
      </a> */}
    </div>
  );
};

const Header = () => {
  const context = useContext(Context);
  const { menuStatus } = context.state;
  const [showAuthBlock, toogleAuthBlock] = useState(false);

  const dropdownMenu = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownMenu.current && !dropdownMenu.current.contains(event.target)) {
      toogleAuthBlock(false);
    }
  };

  const handleHeaderAuth = () => {
    context.updateState('menuStatus', false);
    toogleAuthBlock(!showAuthBlock);
  };

  const clickLogo = () => {
    context.clearState();
    context.updateState('menuStatus', false);
    window.scrollTo(0, 0);
  };

  const closeMenu = () => {
    context.closeMenu();
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  });

  const headerClasses = classNames('header', { open: menuStatus });
  const authClasses = classNames('header-auth', { open: showAuthBlock });
  const menuClasses = classNames('menu-button', { open: menuStatus });

  return (
    <header className={headerClasses}>
      <div className="header-in">
        <div className="header-top">
          <div className="header-top-menu">
            <Link onClick={() => clickLogo()} className="logo" to="/">
              <Icon
                icon="campus-logo"
                iconClass="header-logo"
                width="12"
                height="6"
                className="logo"
              />
            </Link>
            <TitleMenu closeMenu={closeMenu} />
            <a href='https://t.me/lifecell_campus' className='header-social-icon' target='_blank' rel='noopener noreferrer'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 25"><path fill="#000000" fill-rule="evenodd" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm-3.767-6.063c.366 0 .535-.17.74-.375l1.777-1.724 3.697 2.733c.678.375 1.17.178 1.34-.634l2.428-11.438c.25-1-.375-1.455-1.027-1.16l-14.259 5.5c-.973.392-.964.937-.179 1.16l3.661 1.16 8.464-5.347c.402-.241.768-.107.465.17L8.983 15.16l-.25 3.777z"></path></svg>
            </a>
          </div>
          <div className="menu-button-icon">
            <LanguageSelector className="in-header" />

            <div className={authClasses} ref={dropdownMenu} onClick={handleHeaderAuth}>
              <AuthHeader />
            </div>
            <span onClick={closeMenu} className={menuClasses}></span>
          </div>
        </div>
      </div>
      <div className="header-menu">
        <div className="header-menu-in">
          <TitleMenu closeMenu={closeMenu} />
          <LanguageSelector className="in-header-menu" />
        </div>
      </div>
    </header>
  );
};
export default Header;
